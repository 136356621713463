import './SearchVenue.scss';
import SelectSearch from 'react-select-search';
import { useHistory } from "react-router-dom";

const searchOptions = [
    {name: 'Hyatt Regency Chicago', value: 'sv'},
    {name: 'Omni Chicago', value: 'en'},
    {name: 'The Rio Las Vegas', value: 'ri'},
    {name: 'Hard Rock Las Vegas', value: 'hr'},
];


function SearchVenue(props) {
    const history = useHistory();

    function handleClick() {
        history.push("/review");
    }
    const options = props.venues.map(function(row) {
        return {  name : row.title, value : row.id }
    });
     
    return (
        <div className={"searchvenue " + (props.open? "":"active")}>
            <h2 className="searchvenue__title">{props.title}</h2>
            <SelectSearch 
                options={options} 
                search
                value="sv" 
                onChange={handleClick}
                name="venues" 
                placeholder="Leave a Review for your Meeting Venue" 
            />
        </div>
    );
}

export default SearchVenue;
