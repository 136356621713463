import './RegisterForm.scss';
import Button from '../partials/Button.js';
import React, { useState, useEffect } from 'react';
import { FaHotel } from "react-icons/fa";
import { FaUserFriends } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";

function RegisterForm({handleSetType}) {


  return (
    <div className="form_register_type">
        <h2 className="form-register__title">Choose an account type</h2>
        <p className="small center">To ensure high quality content and genuine reviews, all accounts are manually verified. <br/>Please provide accurate contact information.</p>
        <div className="flex flex-center">
            <a className="form_register_type__choice" onClick={() => handleSetType('meetingplanner')}>
                <h2>I am a Meeting Planner</h2>
                <FaUserFriends  size="150px"/>
            </a>
            <a className="form_register_type__choice" onClick={() => handleSetType('venue')}>
                <h2>I represent a Venue</h2>
                <FaHotel size="150px"/>
            </a>
            <a className="form_register_type__choice" onClick={() => handleSetType('destination')}>
                <h2>I represent a Destination</h2>
                <MdLocationOn size="150px"/>
            </a>
        </div>
    </div>
  );
}

export default RegisterForm;
