import './Footer.scss';
import Button from './Button.js';

function Footer({handler}) {
  return (
    <div className="footer">
        <Button title="Login Test" onClick={handler}/>
    </div>
  );
}

export default Footer;
