import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './RegisterFields.js';
import './RegisterForm.scss';
import { registerFieldsURL, registerURL, AuthStr } from '../partials/Urls.js';
import { FaCheck } from "react-icons/fa";

function RegisterFormMP({handleSetType}) {

    const [regFields, setRegFields] = useState([]);
    const [regFormInput, setRegFormInput] = useState([]);
    const [formErrors, setFormErrors] = useState();


    const [valPw, setValPw] = useState(false);
    const [valEmail, setValEmail] = useState(false);
    const [valScreenname, setValScreenname] = useState(false);
    const [formatPhone, setFormatPhone] = useState();

    function onPhoneChange(text) {
        var cleaned = ('' + text).replace(/\D/g, '')
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            var intlCode = (match[1] ? '+1 ' : ''),
                number = [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    
            setFormatPhone(number);
            return;
        }
    
        this.setState({
            phoneNum: text
        });
    }

    function handleChange(evt) {
        const value =
          evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
          setRegFormInput({
          ...regFormInput,
          [evt.target.name]: value
        });
    }
    
    function handlePassValidate(evt) {
        if(regFormInput['pwval'] === regFormInput['pass']){
            setValPw(true);
        } else {
            setValPw(false);           
        }
        console.log(regFormInput)
    }

    function handleValidateEmail(evt) {
        let regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
         if(regexp.test(evt.target.value) === true){
            setValEmail(true);
        } else {
            setValEmail(false);           
        }
    }

    function handleValidateScreenname(evt) {
        let regexp = /^[a-zA-Z0-9_.-]*$/;
         if(regexp.test(evt.target.value) === true){
            setValScreenname(true);
        } else {
            setValScreenname(false);           
        }
    }
    
    function doRegister(e) {

        e.preventDefault();
        console.log(regFormInput.email);
        console.log(regFormInput);

        axios.post(registerURL, {regFormInput}, { 'headers': {'X-API-KEY': AuthStr}
        }).then(function (response) {
            console.log(response.data);

            if(response.data.error) {
                setFormErrors(response.data.error);
            } else if (response.data.success === true) {
                console.log('success');
                handleSetType('success');
            }

        }).catch(function (error) {
            console.log(error.response.data);
            setFormErrors(error.response.data.error);

        });  
    }

    useEffect(() => {

        axios.get(registerFieldsURL, {
            'headers': {
                'X-API-KEY': AuthStr
            }
        }).then(function (response) {
            setRegFields(response.data.fields);
            console.log(response.data.fields);   
        }).catch(function (error) {
            console.log(error);
        });

    }, []);


    return (
        <div className="form-register">

            {regFields.map((field, index) => (
                <div className={field.type} key={index}>
                    
                    {field.type == 'FieldtypeEmail' && 
                    <div className="fieldwrap box-muted formflex">
                        <div className="formflex__field">
                            <label>Email Address (this will be your username)</label>
                            <input onChange={handleChange} onBlur={handleValidateEmail} name={field.name} type='email'></input>
                        </div>
                        <div className={`formflex__val ${valEmail ? "is-active" : "" } `}>
                            <FaCheck/>
                        </div>
                    </div>
                    }
                    {field.type == 'FieldtypePassword' && 
                    <div className="fieldwrap box-muted formflex">
                        <div className="formflex__field">
                            <label>{field.label}</label>
                            <input  onBlur={handlePassValidate} onChange={handleChange} name={field.name} type='password'></input>
                            <label>Verify Password</label>
                            <input onBlur={handlePassValidate} onChange={handleChange} name="pwval" type='password'></input>
                        </div>
                        <div className={`formflex__val ${valPw ? "is-active" : "" } `}>
                            <FaCheck/>
                        </div>
                    </div>
                    }
                    {field.type == 'FieldtypeText' && 
                    <div className="fieldwrap box-muted formflex">
                        <div className="formflex__field">
                            <label>{field.label}</label>
                            <input onBlur={handleValidateScreenname} onChange={handleChange} name={field.name}  type='text'></input>
                        </div>
                        <div className={`formflex__val ${valScreenname ? "is-active" : "" } `}>
                            <FaCheck/>
                        </div>
                    </div>
                    }
                    {field.type == 'FieldtypePhone' && 
                    <div className="fieldwrap box-muted formflex">
                        <div className="formflex__field">
                            <label>{field.label} (optional)</label>
                            <input onChange={handleChange} name={field.name} value={formatPhone} type='text'></input>
                        </div>
                        <div className="formflex__val">
                            <FaCheck/>
                        </div>
                    </div>
                    }
                    {field.type == 'FieldtypeTextarea' && 
                    <div className="fieldwrap box-muted formflex">
                        <div className="formflex__field">
                            <label>{field.label}</label>
                            <textarea onChange={handleChange} name={field.name}  type='text'></textarea>
                        </div>
                        <div className="formflex__val">
                            <FaCheck/>
                        </div>
                    </div>
                    }

                </div>

            ))}

            {formErrors &&
                <div className="form__errors">
                    {formErrors}
                </div>
            }

            <div className="">
                <button onClick={doRegister}>Submit</button>
            </div>
                
        </div>
    );
}

export default RegisterFormMP;
