
import "./HamburgerSettings.scss";
import "../../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss";
import "./Hamburger.scss";
import React, { useState } from 'react';




function Hamburger() {

    const [isOpen, setIsOpen] = useState(false);

    function handleClick() {
        setIsOpen(!isOpen);
    }

    return (
        <button className={`hamburger hamburger--squeeze ${isOpen ? "is-active" : ""}`} onClick={handleClick} >
            <span className="hamburger-box">
                <span className="hamburger-inner"></span>
            </span>
        </button>
    );
}

export default Hamburger;
