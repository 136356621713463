import './RegisterForm.scss';
import Button from '../partials/Button.js';

function RegisterFormDestination() {
 
    return (
        <div className="form-register">
            Destination Form
        </div>
    );
}

export default RegisterFormDestination;
