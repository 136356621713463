import './Venue.scss';

// URLS + Auth
import {fetchVenueUrl} from '../partials/Urls.js';
import {AuthStr} from '../partials/Urls.js';

import {
    Link,
    useParams
  } from "react-router-dom";
  import '../partials/CardBlock.scss';
  import React, { useState, useEffect } from 'react';
  import axios from 'axios';



function Venue(props) {

    const [venueData, setVenueData] = useState({});
    let id = useParams();
    id = id.id;
    id = parseInt(id);

    useEffect(() => {
        axios.get(fetchVenueUrl(id), { 'headers': { 'X-API-KEY': AuthStr } })
            .then(response => setVenueData(response.data));
            console.log(venueData);  
    }, []);

    return (
    <div className="venue">

        <div className="grid">
            <div className="review__header col-sm-1  col-md-8 col-lg-8 col-xl-8">
                <h1>{venueData.title}</h1>
                <p>676 North Michigan Avenue, <br/>
                Chicago, IL 60611-2883</p>
            </div>
        </div>
        <div className="grid">
            <div className="venue__body col-sm-1  col-md-8 col-lg-8 col-xl-8">
                <p>Consetetur lorem ipsum dolor sit amet,  sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut  et dolore magna  erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum  sit amet. Lorem ipsum dolor sit amet, consetetur  elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna  erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
                <p>Lorem sadipscing ipsum dolor sit amet, consetetur  elitr, sed diam  labore eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. Eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata  est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur  elitr, sed diam nonumy eirmod  invidunt ut labore et  magna aliquyam erat, sed diam voluptua. At et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
                <p>Lorem  dolor sit amet, consetetur sadipscing ipsum elitr, sed diam  eirmod tempor invidunt ut labore nonumy et dolore magna aliquyam erat, sed diam voluptua. At  eos et  et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem  dolor sit amet. Lorem ipsum dolor sit amet, consetetur  elitr, sed diam nonumy eirmod tempor invidunt ut  et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
                <p>Sadipscing lorem ipsum dolor sit amet, consetetur  elitr, sed diam nonumy eirmod tempor invidunt ut labore et  magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd , no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,  sadipscing elitr, sed diam  eirmod tempor invidunt ut labore et dolore magna  erat, sed diam . At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
            </div>
            <div className="venue__related col-sm-1 col-md-4 col-lg-4 col-xl-4">  
                <h2>Latest Reviews</h2>     
                {props.data.map((card, index) => (
                <div className="" key={index}>
                    <div className="cardBlock__card">
                        <Link className="cardBlock__card__blocklink" to="/article"></Link>
                        <div className="cardBlock__card__header" style={{'backgroundImage': `url(${card.photo})`}}>
                        </div>
                        <div className="cardBlock__card__body">
                        </div>
                        <div className="cardBlock__card__footer">
                            <div className="box"><h3>{card.title}</h3></div>
                        </div>
                    </div>
                </div>
                ))}
            </div>
        </div>
    </div>
    );
}

export default Venue;
