import './AddVenue.scss';
import FindVenue from '../partials/FindVenue.js';
import AddVenueForm from '../partials/AddVenueForm.js';

import {
    Link
  } from "react-router-dom";

function AddVenue(props) {
  return (
    <div className="addvenue">

        <div className="grid">

        </div>
        <div class="grid">
            <div className="venue__body col-sm-1  col-md-8 col-lg-12 col-xl-12">
                <FindVenue title="Search for Your Venue"/>
            </div>
            <div class="addvenue__form col-sm-1  col-md-8 col-lg-8 col-xl-8">
                <AddVenueForm/>
            </div>
        </div>
    </div>
  );
}

export default AddVenue;
