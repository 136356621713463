import './AddVenueForm.scss';
import './form.scss';

import Rating from 'react-rating';
import ButtonToggle from './ButtonToggle';

const ratingqs = [
    {
        question: "How well does the hotel adhere to safety and sanitation standards?",
    },{
        question: "How would you rate this destination as a site for your event?",
    },{
        question: "How would you rate how this venue performed for your event?",
    },{
        question: "How would you rate how flexible this venue was with your event contract?",
    }
]

const ynqs = [
    {
        question: "Lorem Ipsum?",
    },{
        question: "Lorem Ipsum?",
    },{
        question: "Lorem Ipsum?",
    },{
        question: "Lorem Ipsum?",
    },{
        question: "Lorem Ipsum?",
    },{
        question: "Lorem Ipsum?",
    },{
        question: "Lorem Ipsum?",
    },{
        question: "Lorem Ipsum?",
    },{
        question: "Lorem Ipsum?",
    },{
        question: "Lorem Ipsum?",
    },{
        question: "Lorem Ipsum?",
    },{
        question: "Lorem Ipsum?",
    },{
        question: "Lorem Ipsum?",
    }
]

function AddVenueForm(props) {
  return (
    <div className="form">
        <div className="form__qset">
            <div className="form__input-wrap">
                <label>Hotel Name</label><br/>
                <input type="text"></input>
            </div>
            <div className="form__input-wrap">
                <label>Address 1</label><br/>
                <input type="text"></input>
            </div>
            <div className="form__input-wrap">
                <label>Address 2</label><br/>
                <input type="text"></input>
            </div>
            <div className="form__input-wrap">
                <label>Description</label><br/>
                <textarea></textarea>
            </div>
            <div className="form__input-wrap">
                <label>Lorem Ipsum</label><br/>
                <textarea></textarea>
            </div>
        </div>
        <div className="form__qset">
            {ynqs.map((question, index) => (
            <div className="form-ynqs" key={index}>
                <div className="form-ynqs__question">
                    {question.question}
                </div>
                <div className="form-ynqs__rating">
                    <ButtonToggle title="Yes"/>
                    <ButtonToggle title="No"/>
                </div>
            </div>
            ))}
        </div>
    </div>
  );
}

export default AddVenueForm;
