import './ReviewForm.scss';
import './form.scss';

import Rating from 'react-rating';
import ButtonToggle from './ButtonToggle';

const ratingqs = [
    {
        question: "How well does the hotel adhere to safety and sanitation standards?",
    },{
        question: "How would you rate this destination as a site for your event?",
    },{
        question: "How would you rate how this venue performed for your event?",
    },{
        question: "How would you rate how flexible this venue was with your event contract?",
    }
]

const ynqs = [
    {
        question: "Contactless check-in?",
    },{
        question: "Temperature checks at door?",
    },{
        question: "Employees all wear masks",
    },{
        question: "Hand Sanitizers in public areas",
    },{
        question: "Clean rooms seal",
    },{
        question: "Sanitary Amenities in guest room (wipes, hand sanitizer, etc)",
    },{
        question: "Safe banquet food and beverage options",
    },{
        question: "Digital menus in restaurants (QR code)",
    },{
        question: "Social distancing meeting rooms set-ups",
    },{
        question: "Hybrid meeting capability",
    },{
        question: "Contract attrition flexibility",
    },{
        question: "Contract cancellation flexibility",
    },{
        question: "% of employees fully vaccinated",
    }
]

function ReviewForm(props) {
  return (
    <div className="form">
        <div className="form__qset">
            {ratingqs.map((question, index) => (
            <div className="form-bubble" key={index}>
                <div className="form-bubble__question">
                    {question.question}
                </div>
                <div className="form-bubble__rating">
                    <Rating />
                </div>
            </div>
            ))}
        </div>
        <div className="form__qset">
            <div className="form-textarea">
                <label>What information should your event planning colleagues know before selecting this destination? (optional)</label><br/>
                <textarea></textarea>
            </div>
            <div className="form-textarea">
                <label>What information should your event planning colleagues know before selecting this destination? (optional)</label><br/>
                <textarea></textarea>
            </div>
        </div>
        <div className="form__qset">
            {ynqs.map((question, index) => (
            <div className="form-ynqs" key={index}>
                <div className="form-ynqs__question">
                    {question.question}
                </div>
                <div className="form-ynqs__rating">
                    <ButtonToggle title="Yes"/>
                    <ButtonToggle title="No"/>
                    <ButtonToggle title="Not Sure"/>
                </div>
            </div>
            ))}
        </div>
    </div>
  );
}

export default ReviewForm;
