import './Venue.scss';

// URLS + Auth
import {dashboardURL} from '../partials/Urls.js';
import {AuthStr, loginURL} from '../partials/Urls.js';

import {
    Link,
    useParams
  } from "react-router-dom";
  import '../partials/CardBlock.scss';
  import React, { useState, useEffect } from 'react';
  import axios from 'axios';


function GetDashboard({accessToken, refreshToken, userLoggedIn, password, username}) {

    const [pageData, setPageData] = useState({});

    useEffect(() => {

        axios.get(dashboardURL, {
            'headers': {
                'X-API-KEY': AuthStr,
                //'authorization': 'Basic ' + btoa(username + ':' + password),
                'authorization': 'Bearer ' + accessToken
            }
        }).then(function (response) {
            console.log(response);
        }).catch(function (error) {
            console.log(error);
          })
        },
    [pageData]);

    return (

    <div className="venue">

        <div className="grid">
            <div className="review__header col-sm-1  col-md-8 col-lg-8 col-xl-8">
                <h1>{pageData.title}</h1>

            </div>
        </div>
        <div className="grid">
            <div className="venue__body col-sm-1  col-md-8 col-lg-8 col-xl-8">
                <div dangerouslySetInnerHTML={ { __html: pageData.body } }></div>
            </div>
        </div>
    </div>
    );
}

export default GetDashboard;
