import './RegisterForm.scss';
import Button from '../partials/Button.js';
import RegisterForm_type from './RegisterForm_type.js';
import RegisterFormDestination from './RegisterFormDestination.js';
import RegisterFormMP from './RegisterFormMP.js';
import RegisterFormVenue from './RegisterFormVenue.js';
import RegisterFormSuccess from './RegisterFormSuccess.js';

import SwitchComponents from '../helpers/SwitchComponents.js';

import React, { useState, useEffect } from 'react';

function RegisterForm() {

    const [registerType, setRegisterType] = useState('start');

    const handleSetType = (type) => {
        setRegisterType(type);
        console.log(type);
    }    

    return (
        <div className="form-register">

            <SwitchComponents active={registerType}>
                <RegisterForm_type name="start" handleSetType={handleSetType}/>
                <RegisterFormDestination name="destination"/>
                <RegisterFormMP name="meetingplanner" handleSetType={handleSetType}/>
                <RegisterFormVenue name="venue" />
                <RegisterFormSuccess name="success" />
            </SwitchComponents>

            {registerType!=='start' &&
            <Button onClick={() => handleSetType("start")} title="go back" type="btn--center btn--block btn--text" ></Button>
            }
            
        </div>
    );
}

export default RegisterForm;
