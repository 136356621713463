



// local


//export const AuthStr = 'fzeCCIgHEap0b3JTsKMnQ';
//const base_url = 'http://msa.localhost/data/api/v1/';
//const base_li_url = 'http://msa.localhost/data/api/';


// Dev

export const AuthStr = 'fzeCCIgHEap0b3JTsKMnQ';
const base_url = 'https://data.meetingsiteadvisor.com/data/api/v1/';
const base_li_url = 'https://data.meetingsiteadvisor.com/data/api/';


export const venuesURL = base_url + 'venues';
export const dashboardURL = base_url + 'dashboard';
export const loginURL = base_li_url + 'auth';
export const registerFieldsURL = base_url + 'preregister';
export const registerURL  = base_url + 'register';
export const registerEmailVal  = base_url + 'emailval';

export const accessURL = base_li_url + 'auth/access';
export var fetchVenueUrl = (id) => base_url + `venues/${id}`;
export var fetchPagePath = (path) => base_url + `content/${path}`;




