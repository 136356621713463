import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
  import './CardBlock.scss';


function CardBlock(props) {
  return (
    <div className="cardBlock">
        <h2 className="cardBlock__title">{props.title}</h2>
        <div className="grid">
        {props.data.map((card, index) => (
            <div className="col-sm-1 col-md-1 col-lg-4 col-xl-3" key={index}>
                <div className="cardBlock__card" id={card.id}>
                    <Link className="cardBlock__card__blocklink" to={`venue/${card.id}/${card.urlTitle}`}></Link>

                    {props.photo===true && card.photo.length > 0 &&
                        <div className="cardBlock__card__header" style={{'backgroundImage': `url(${card.photo})`}}>
                        </div>
                    }

                    <div className="cardBlock__card__body">
                    </div>
                    <div className="cardBlock__card__footer">
                        <div className="box"><h3>{card.title}</h3></div>
                    </div>
                </div>
            </div>
        ))}
        </div>
    </div>
  );
}

export default CardBlock;
