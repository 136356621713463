import './Nav.scss';
import Button from './Button.js';
import Hamburger from './Hamburger.js';
import React, { useEffect } from 'react';
import { BiUserCircle } from "react-icons/bi";
import { BiLogOut } from "react-icons/bi";
import { FiPlus } from "react-icons/fi";
import { FaPen } from "react-icons/fa";
import { GoPlus } from "react-icons/go";


import NavbarDropdown from "react-navbar-dropdown";
import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";

function Nav({ openLogin, logOut, openRegister, userLoggedIn }) {

    let userActions;
    if (userLoggedIn === true) {
        userActions =
            <ul className="nav__wrap__menu">
                <li className="nav__wrap__menu__item"><Link to="/my-dashboard">My Dashboard</Link></li>
                <li className="nav__wrap__menu__item">

                    <NavbarDropdown>
                        <NavbarDropdown.Toggle className="menu__item">
                            <NavbarDropdown.Open>
                                <BiUserCircle />
                            </NavbarDropdown.Open>
                            <NavbarDropdown.Close>
                                <BiUserCircle />
                            </NavbarDropdown.Close>
                        </NavbarDropdown.Toggle>
                        <NavbarDropdown.CSSTransitionMenu
                            className="example1-dropdown-menu"
                            classNames="example1-dropdown-menu"
                            timeout={200}
                        >
                            <NavbarDropdown.Item className="example1-dropdown-menu-item" onClick={() => alert('Item 1: clicked!')}>
                                <div>
                                    <BiUserCircle />

                                </div>
                                <div className="example1-dropdown-menu-item__spacer" />
                                <div className="example1-dropdown-menu-item__text">Item 1</div>
                            </NavbarDropdown.Item>
                            <NavbarDropdown.Item className="example1-dropdown-menu-item" onClick={() => alert('Item 2: clicked!')}>
                                <div>
                                    <BiUserCircle />
                                </div>
                                <div className="example1-dropdown-menu-item__spacer" />
                                <div className="example1-dropdown-menu-item__text">Item 2</div>
                            </NavbarDropdown.Item>
                            <NavbarDropdown.Item className="example1-dropdown-menu-item" onClick={() => alert('Item 3: clicked!')}>
                                <div>
                                    <BiUserCircle />
                                </div>
                                <div className="example1-dropdown-menu-item__spacer" />
                                <div className="example1-dropdown-menu-item__text">Item 3</div>
                            </NavbarDropdown.Item>
                            <NavbarDropdown.Item className="example1-dropdown-menu-item" onClick={logOut}>
                                <div>
                                    <BiLogOut />
                                </div>
                                <div className="example1-dropdown-menu-item__spacer" />
                                <div className="example1-dropdown-menu-item__text">Logout</div>
                            </NavbarDropdown.Item>
                        </NavbarDropdown.CSSTransitionMenu>
                    </NavbarDropdown>
                    
                </li>
            </ul>;
    } else {
        userActions =
            <><Button title="Login" onClick={openLogin} />
                <Button title="Register" onClick={openRegister} /></>;
    }
    useEffect(() => {
        console.log(userLoggedIn);
    }, [userLoggedIn]);

    return (
        <div className="nav">
            <nav className="nav__wrap max-width">
                <span className="nav__logo"><Link to="/">MeetingSiteAdvisor</Link></span>
                <div className="nav__menu">
                    <ul className="nav__list">
                        <li className="nav__item"><Link to="/"><GoPlus /> Add Your Venue</Link></li>
                        <li className="nav__item"><FaPen /> Review a Venue</li>
                        <li className="nav__item"><Link to="page/about">About Us</Link></li>
                    </ul>
                    <div className="nav__buttons">
                        {userActions}
                    </div>
                </div>
                <div className="nav__hamburger">
                    <Hamburger />
                </div>

            </nav>
        </div>
    );
}

export default Nav;
