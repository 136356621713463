import './Review.scss';
import ReviewForm from '../partials/ReviewForm.js';

function Review(props) {
  return (
    <div className="review">
        <div class="grid">
            <div class="col-sm-1 col-md-2 col-lg-8 col-xl-8">
                <div class="review__header">
                    <h2>Review your recent meeting at </h2>
                    <h1>Omni Chicago Hotel</h1>
                    <p>676 North Michigan Avenue, <br/>
                    Chicago, IL 60611-2883</p>
                </div>
                <div class="review__form">
                    <ReviewForm/>
                </div>
            </div>
            <div class="col-sm-1 col-md-2 col-lg-4 col-xl-4">
                <div class="review__photo">
                    <img src="./img/omni@2x.jpg" width="100%"/>
                </div>
            </div>
        </div>

    </div>
  );
}

export default Review;
