import './RegisterForm.scss';

function RegisterFormSuccess() {
 
    return (
        <div className="form-register">
            <p className="center">Success! Thank you for registering! An email has just been sent to you with the link to activate your account</p>
        </div>
    );
}

export default RegisterFormSuccess;
