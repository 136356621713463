import './FindVenue.scss';
import SelectSearch from 'react-select-search';
import { useHistory } from "react-router-dom";

const searchOptions = [
    { name: 'Hyatt Regency Chicago', value: 'sv' },
    { name: 'Omni Chicago', value: 'en' },
    { name: 'The Rio Las Vegas', value: 'ri' },
    { name: 'Hard Rock Las Vegas', value: 'hr' },
];

function FindVenue(props) {
    const history = useHistory();

    function handleClick() {
        history.push("/venue");
    }

    const options = props.venues.map(function(row) {
        return {  name : row.title, value : row.id }
    });

    return (
        <div className="grid">
            <div className="findvenue">
                <h2 className="findvenue__title">{props.title}</h2>
                <SelectSearch 
                    options={options} 
                    placeholder="Leave a Review for your Meeting Venue" 
                    search
                    debounce={500}
                    value="sv" 
                    onChange={handleClick}
                    name="venues" 
                />
            </div>
        </div>
    );
}

export default FindVenue;
