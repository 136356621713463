import './RegisterForm.scss';
import Button from '../partials/Button.js';
import RegisterFormVenueStep1 from './RegisterFormVenueStep1.js';
import RegisterFormVenueStep2 from './RegisterFormVenueStep2.js';
import RegisterFormVenueStep3 from './RegisterFormVenueStep3.js';

function RegisterFormVenue() {
 
    return (
        <div className="form-register">
            Venue Form
        </div>
    );
}

export default RegisterFormVenue;
