import './Venue.scss';

// URLS + Auth
import {fetchPagePath} from '../partials/Urls.js';
import {AuthStr} from '../partials/Urls.js';

import {
    Link,
    useParams
  } from "react-router-dom";
  import '../partials/CardBlock.scss';
  import React, { useState, useEffect } from 'react';
  import axios from 'axios';


function GetPage(props) {

    const [pageData, setPageData] = useState({});
    let path = useParams();
    path = path.path;
    //id = parseInt(id);

    useEffect(() => {
        axios.get(fetchPagePath(path), { 'headers': { 'X-API-KEY': AuthStr } })
            .then(response => setPageData(response.data));
            console.log(pageData);  
    }, []);

    return (

    <div className="venue">

        <div className="grid">
            <div className="review__header col-sm-1  col-md-8 col-lg-8 col-xl-8">
                <h1>{pageData.title}</h1>

            </div>
        </div>
        <div className="grid">
            <div className="venue__body col-sm-1  col-md-8 col-lg-8 col-xl-8">
                <div dangerouslySetInnerHTML={ { __html: pageData.body } }></div>
            </div>
        </div>
    </div>
    );
}

export default GetPage;
