import './normalize.css';
import './App.scss';
import './Type.scss';
import Nav from './partials/Nav.js';
import SearchVenue from './partials/SearchVenue.js';
import CardBlock from './partials/CardBlock.js';
import Footer from './partials/Footer.js';
import FindVenue from './partials/FindVenue.js';

import Article from './pages/Article.js';
import GetDashboard from './pages/GetDashboard.js';
import Venue from './pages/Venue.js';
import GetPage from './pages/GetPage.js';
import Review from './pages/Review.js';
import AddVenue from './pages/AddVenue.js';
import RegisterForm from './forms/RegisterForm.js';

import axios from 'axios';
import Modal from 'react-modal';

import { useCookies } from 'react-cookie';

import { MdClose } from "react-icons/md";


// URLS + Auth
import {venuesURL, fetchPagePath, loginURL, accessURL, AuthStr} from './partials/Urls.js';

import React, { useState, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
  } from "react-router-dom";

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
  };

const cardData1 = [
    {
        title: "Article Ones",
        photo: "https://www.peninsula.com/en/-/media/images/chicago/04_weddingmeetingsevents-final/c_meeting-events_venues_photos/millennium-park-banquet-rounds_the-peninsula-chicago.jpg?mw=987&hash=EA4C6DF38A4C6295E85192AEAA89F13F9F0C03DF"
    },{
        title: "Article Two",
        photo: "https://www.peninsula.com/en/-/media/images/chicago/04_weddingmeetingsevents-final/c_meeting-events_venues_photos/millennium-park-banquet-rounds_the-peninsula-chicago.jpg?mw=987&hash=EA4C6DF38A4C6295E85192AEAA89F13F9F0C03DF"
    },{
        title: "Article Three",
        photo: "https://www.peninsula.com/en/-/media/images/chicago/04_weddingmeetingsevents-final/c_meeting-events_venues_photos/millennium-park-banquet-rounds_the-peninsula-chicago.jpg?mw=987&hash=EA4C6DF38A4C6295E85192AEAA89F13F9F0C03DF"
    },{
        title: "Article Four",
        photo: "https://www.peninsula.com/en/-/media/images/chicago/04_weddingmeetingsevents-final/c_meeting-events_venues_photos/millennium-park-banquet-rounds_the-peninsula-chicago.jpg?mw=987&hash=EA4C6DF38A4C6295E85192AEAA89F13F9F0C03DF"
    }
];

const cardData2 = [
    {
        title: "Article 5",
        photo: "https://www.peninsula.com/en/-/media/images/chicago/04_weddingmeetingsevents-final/c_meeting-events_venues_photos/millennium-park-banquet-rounds_the-peninsula-chicago.jpg?mw=987&hash=EA4C6DF38A4C6295E85192AEAA89F13F9F0C03DF"
    },{
        title: "Article 6",
        photo: "https://www.peninsula.com/en/-/media/images/chicago/04_weddingmeetingsevents-final/c_meeting-events_venues_photos/millennium-park-banquet-rounds_the-peninsula-chicago.jpg?mw=987&hash=EA4C6DF38A4C6295E85192AEAA89F13F9F0C03DF"
    },{
        title: "Article 7",
        photo: "https://www.peninsula.com/en/-/media/images/chicago/04_weddingmeetingsevents-final/c_meeting-events_venues_photos/millennium-park-banquet-rounds_the-peninsula-chicago.jpg?mw=987&hash=EA4C6DF38A4C6295E85192AEAA89F13F9F0C03DF"
    },{
        title: "Article 8",
        photo: "https://www.peninsula.com/en/-/media/images/chicago/04_weddingmeetingsevents-final/c_meeting-events_venues_photos/millennium-park-banquet-rounds_the-peninsula-chicago.jpg?mw=987&hash=EA4C6DF38A4C6295E85192AEAA89F13F9F0C03DF"
    },{
        title: "Article 9",
        photo: "https://www.peninsula.com/en/-/media/images/chicago/04_weddingmeetingsevents-final/c_meeting-events_venues_photos/millennium-park-banquet-rounds_the-peninsula-chicago.jpg?mw=987&hash=EA4C6DF38A4C6295E85192AEAA89F13F9F0C03DF"
    },{
        title: "Article 10",
        photo: "https://www.peninsula.com/en/-/media/images/chicago/04_weddingmeetingsevents-final/c_meeting-events_venues_photos/millennium-park-banquet-rounds_the-peninsula-chicago.jpg?mw=987&hash=EA4C6DF38A4C6295E85192AEAA89F13F9F0C03DF"
    }
];

const relatedData = [
    {
        title: "Consetetur Sadipscing",
        photo: "https://www.peninsula.com/en/-/media/images/chicago/04_weddingmeetingsevents-final/c_meeting-events_venues_photos/millennium-park-banquet-rounds_the-peninsula-chicago.jpg?mw=987&hash=EA4C6DF38A4C6295E85192AEAA89F13F9F0C03DF"
    },{
        title: "Stet clita kasd",
        photo: "https://www.peninsula.com/en/-/media/images/chicago/04_weddingmeetingsevents-final/c_meeting-events_venues_photos/millennium-park-banquet-rounds_the-peninsula-chicago.jpg?mw=987&hash=EA4C6DF38A4C6295E85192AEAA89F13F9F0C03DF"
    },{
        title: "Nonumy eirmod tempor invidunt",
        photo: "https://www.peninsula.com/en/-/media/images/chicago/04_weddingmeetingsevents-final/c_meeting-events_venues_photos/millennium-park-banquet-rounds_the-peninsula-chicago.jpg?mw=987&hash=EA4C6DF38A4C6295E85192AEAA89F13F9F0C03DF"
    }
];

var venueArray;

function App() {

// MODAL STUFF

var subtitle;
const [modalLoginIsOpen,setLoginIsOpen] = React.useState(false);
const [modalRegisterIsOpen,setRegisterIsOpen] = React.useState(false);

function openLoginModal() {
    setLoginIsOpen(true);
}
function openRegisterModal() {
    setRegisterIsOpen(true);
  }
function afterOpenLoginModal() {
  // references are now sync'd and can be accessed.
  subtitle.style.color = '#333';
}
function afterOpenRegisterModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#333';
  }
function closeLoginModal(){
    setLoginIsOpen(false);
}
function closeRegisterModal(){
    setRegisterIsOpen(false);
  }
///////


    const [data, setData] = useState([]);
    const [username, setUsername] = useState()
    const [password, setPassword] = useState()
    const [accessToken, setAccessToken] = useState()
    const [refreshToken, setRefreshToken] = useState()
    const [userLoggedIn, setUserLoggedIn] = useState(false)
    const [registerStep, setRegisterStep] = useState([]);

    const [cookies, setCookie] = useCookies(['cookieAccessToken', 'cookieRefreshToken']);

    const handleChangeUsername = e => {
        setUsername(e.target.value)
      }
      const handleChangePassword = e => {
        setPassword(e.target.value)
      }    

    function testLogin() {
        //console.log(accessToken);
        axios.get(loginURL, { 'headers': {
            'X-API-KEY': AuthStr,
            'Authorization': 'Bearer ' + cookies.cookieAccessToken
        }}).then(function (response) {
            console.log(response.data.name);
            console.log(response.data.loggedIn);
            alert("User: "+ response.data.name+ " is Logged in?: " + response.data.loggedIn);
        }).catch(function (error) {
            console.log(error);
        });  
    }

    function logOut() {

        axios.delete(loginURL, { 'headers': {
            'X-API-KEY': AuthStr,
            'Authorization': 'Bearer ' + cookies.cookieAccessToken
        }}).then(function (response) {
            console.log(response);
            console.log("User: "+ response.data.name+ " is Logged Out: " + response.data.loggedIn);
            setUserLoggedIn(false);
        }).catch(function (error) {
            console.log(error.response.data);
        });  
    }


    function handleLogin(e) {

        e.preventDefault();

        setUsername(username);
        setPassword(password);

        axios.post(loginURL, {}, { 'headers': { 'X-API-KEY': AuthStr },
          auth: {
            username: username,
            password: password
          } })
            .then(function (response) {
                //let token = response.data.refresh_token;
                console.log(response.data.username);
                console.log(response.data.refresh_token);
                setRefreshToken(response.data.refresh_token);

                axios.get(accessURL, { 'headers': { 
                    'X-API-KEY': AuthStr,
                    'authorization': `Bearer ${response.data.refresh_token}` }})
                .then(function (response) {
                    console.log(response);

                    setCookie('cookieRefreshToken', response.data.refresh_token, { path: '/' });
                    setCookie('cookieAccessToken', response.data.access_token, { path: '/' });

                    setRefreshToken(response.data.refresh_token);
                    setAccessToken(response.data.access_token);
                    console.log(response.data.access_token);
                    setUserLoggedIn(true);
                    closeLoginModal();
                }).catch(function (error) {
                    //console.log(error);
                    console.log(error.response.data);
                  });
            })
            .catch(function (error) {
              console.log(error);
              console.log(error.response.data);
            });
    }
    useEffect(() => {
        console.log(userLoggedIn);
    }, [userLoggedIn]);

    useEffect(() => {
        // GET request using axios inside useEffect React hook
        if(cookies.cookieAccessToken){
            axios.get(loginURL, { 'headers': {
                'X-API-KEY': AuthStr,
                'Authorization': 'Bearer ' + cookies.cookieAccessToken
            }}).then(function (response) {
                console.log(response.data.name);
                console.log(response.data.loggedIn);
                setUserLoggedIn(true);
                //alert("User: "+ response.data.name+ " is Logged in?: " + response.data.loggedIn);
            }).catch(function (error) {
                console.log(error);
            });  
        }

        axios.get(venuesURL, { 'headers': { 'X-API-KEY': AuthStr } })
            .then(response => setData(response.data.venues));
            console.log(data);  

    }, []);

  return (
    <div className="container">
        <Router basename="/msa">
        <Nav openLogin={openLoginModal} logOut={logOut} userLoggedIn={userLoggedIn} openRegister={openRegisterModal}/>
        <Switch>
            <Route path="/article">
                <SearchVenue venues={data} title="Review Your Recent Meeting Venue" open={false}/>
            </Route>
            <Route path="/review">
                <SearchVenue venues={data} title="Review Your Recent Meeting Venue" open={false}/>
            </Route>
            <Route path="/venue">
                <SearchVenue venues={data} title="Review Your Recent Meeting Venue" open={false}/>
            </Route>
            <Route path="/add-venue">
                <SearchVenue venues={data} title="Review Your Recent Meeting Venue" open={false}/>
            </Route>
            <Route path="/about">
                <SearchVenue venues={data} title="Review Your Recent Meeting Venue" open={false}/>
            </Route>
            <Route path="/">
                <SearchVenue venues={data} title="Review Your Recent Meeting Venue" open={true}/>
            </Route>
        </Switch>
            <Switch>
                <Route path="/article">
                    <Article data={relatedData}/>
                </Route>
                <Route path="/review">
                    <div className="wrap">
                        <Review/>
                    </div>
                </Route>
                <Route path="/add-venue">
                    <div className="wrap">
                        <AddVenue/>
                    </div>
                </Route>
                <Route path="/my-dashboard">
                    <div className="wrap">
                        <GetDashboard  username={username} password={password} accessToken={cookies.cookieAccessToken} refreshToken={refreshToken} userLoggedIn={userLoggedIn}/>
                    </div>
                </Route>
                <Route path="/venue/:id/">
                    <div className="wrap">
                        <Venue data={relatedData}/>
                    </div>
                </Route>
                <Route path="/page/:path/">
                    <div className="wrap">
                        <GetPage data={relatedData}/>
                    </div>
                </Route>
                <Route path="/">
                    <div className="wrap">
                        <FindVenue venues={data} title="Find a Venue"/>
                        <CardBlock photo={true} data={cardData1} title="Editorial Reviews"/>
                        <CardBlock photo={true} data={cardData2} title="Latest Articles"/>
                        <CardBlock photo={false} data={data} title="Latest Venues"/>
                    </div>
                </Route>
            </Switch>
        <Footer handler={testLogin}/>
        <Modal
          isOpen={modalLoginIsOpen}
          onAfterOpen={afterOpenLoginModal}
          onRequestClose={closeLoginModal}
          style={customStyles}
          contentLabel="Login Modal"
          className="modal"
          overlayClassName="Overlay"
          ariaHideApp={false}
        >
          <h2 ref={_subtitle => (subtitle = _subtitle)}>Login</h2>
          <div className="modal__close"><MdClose onClick={closeLoginModal} /></div>
          <form className="modal__form" onSubmit={handleLogin}>
            <div className="modal__form__input">
                <label>Username</label>
                <input
                type="text"
                value={username}
                onChange={handleChangeUsername}
                />
            </div>
            <div className="modal__form__input">
                <label>Password</label>
                <input type="password" value={password} onChange={handleChangePassword}/>
            </div>
            <button className="btn" type="submit">Login</button>
          </form>
        </Modal>

        <Modal
          isOpen={modalRegisterIsOpen}
          onAfterOpen={afterOpenRegisterModal}
          onRequestClose={closeRegisterModal}
          //style={customStyles}
          
          contentLabel="Register Modal"
          className="modal modal--wide"
          overlayClassName="Overlay"
          ariaHideApp={false}
        >
          <h2 className="modal__title" ref={_subtitle => (subtitle = _subtitle)}>Register</h2>
          <div className="modal__close" ><MdClose onClick={closeRegisterModal} /></div>
          <form className="modal__form">
            <RegisterForm/>
          </form>
        </Modal>
        </Router>
    </div>
  );
}

export default App;
